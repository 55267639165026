import React, { memo, FC } from "react";

interface IconProps {
  width: number;
}

const Icon: FC<IconProps> = ({ width }) => {
  return (
    <svg width={width} viewBox="0 0 19338 20385" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19338 5437.09L14296.3 8172.53L14278.7 8138.06C13353.3 6328.91 11316.9 5379.92 9336.35 5834.88L7172.51 489.327L7385.42 425.974C12054.6 -963.3 17055.6 1133.38 19338 5437.09ZM5651.72 1110.52L7815.57 6450.62L7071.11 7210.33C4386.39 9950.08 6292.55 14571.7 10128.1 14622.2L11113.1 14635.1L13277 19997L11825.6 20232.6C335.465 22097.6 -4759.64 6316.76 5651.72 1110.52ZM14078.3 12640.8L19120 15398C18123.3 17088.4 16664.7 18458.9 14915.6 19348.6L14797.7 19408.5L12633.9 14057.5L12653.8 14045.5C13232 13695.1 13719.9 13214 14078.3 12640.8Z"
        fill="url(#paint0_linear_16640_88152)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_16640_88152"
          x1="9668.94"
          y1="-632.116"
          x2="9668.94"
          y2="20789"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1890FF" />
          <stop offset="0.517013" stopColor="#2396FF" />
          <stop offset="1" stopColor="#B1E2FE" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default memo(Icon);
