import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "@/axiosInstance";
import { notification } from "antd";

export const getDashboardUsers = createAsyncThunk(
  "Dashboard/getDashboardUsers",
  async () => {
    try {
      const { data } = await axiosInstance.get("/admin/dashboard/stats");
      return data;
    } catch (error: any) {
      const {
        response: { data: errorMessage },
      } = error;
      notification.error({
        message: errorMessage,
        placement: "top",
      });
    }
  }
);
