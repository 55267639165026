// @ts-nocheck
import React, { FC, useEffect, useState } from "react";
import { Button, Form, Modal, Table } from "antd";
import { useAppDispatch, useAppSelector } from "@/store/store";
import * as S from "@/pages/users/styles";
import {
  organizationsSelector,
  usersTableLoadingSelector,
  usersTableSelector,
  getPage,
  getLimit,
  getSort,
  getSearch,
} from "@/store/users/selectors";
import {
  createUserThunk,
  deleteUserThunk, editUserThunk,
  getOrganizationsThunk,
  getUsersThunk,
  sendInvitationThunk
} from "@/store/users/thunks";
import { getColumnSearchProps, usersColumns } from "@/utils/helpers/columns";
import UsersSelect from "@/components/Select";
import moment from "moment";
import { UsersListItem } from "@/utils/types/users";
import { Headder } from "@/pages/users/styles";
import FormUser from "../../components/FormUser";
import { 
  setPage, 
  setLimit,
  setSearch,
  setSort,
 } from "@/store/users/reducer";




const Users: FC = () => {
  const [formEdit] = Form.useForm();
  const [formAdd] = Form.useForm();
  const dispatch = useAppDispatch();
  const usersTableResponse = useAppSelector(usersTableSelector);
  const usersTable = usersTableResponse.users;
  const totalCount = usersTableResponse.totalCount;
  const [currentUser, setCurrentId] = useState('');
  const userTableLoading = useAppSelector(usersTableLoadingSelector);
  const organizations = useAppSelector(organizationsSelector);
  const [isModalActions, setIsModalActions] = useState(false);
  const [isModalAdd, setIsModalAdd] = useState(false);
  const [isModalEdit, setIsModalEdit] = useState(false);

  const page = useAppSelector(getPage);
  const limit = useAppSelector(getLimit);
  const sort = useAppSelector(getSort);
  const search = useAppSelector(getSearch);

  const showModalEdit = () => {
    setIsModalActions(false)
    setIsModalEdit(true)
  }

  const deleteUser = () => {
    setIsModalActions(false)
    dispatch(deleteUserThunk(currentUser.id));
  };

  const sendInvitation = () => {
    setIsModalActions(false)
    if (currentUser?.source === "ADMIN") {
      dispatch(sendInvitationThunk(currentUser.id))
    }
  };

  const onFinishAdd = (values: any) => {
    const selectedOption = organizations.find((opt: { name: any; }) => opt.name === values.organizationName);
    if (selectedOption) {
      values.organizationId = selectedOption.id
      delete values['organizationName']
    }
    dispatch(createUserThunk(values))
    setIsModalAdd(false)
    formAdd.resetFields();
  };

  const onFinishEdit = (values: any) => {
    const selectedOption = organizations.find((opt: { name: any; }) => opt.name === values.organizationName);
    if (selectedOption) {
      values.organizationId = selectedOption.id
      delete values['organizationName']
    }
    values.userId = currentUser.id
    dispatch(editUserThunk(values))
    setIsModalEdit(false)
    formEdit.resetFields();
  };

  const handleChange = (pagination, filters, sorter, extra) => {
    dispatch(setPage(pagination.current));
    dispatch(setLimit(pagination.pageSize));

    const getOrder = (antOrder) => {
      if(antOrder) {
        return sorter.order === "ascend" ? "ASC" : "DESC";
      }
      return undefined;
    }
    const order = getOrder(sorter.order)
    dispatch(setSort({ sortValue: order, sortBy: order ? sorter.field : null }));

    Object.entries(filters).forEach(([name, value]) => {
        if (value) dispatch(setSearch({ searchBy: name, searchValue: value[0] }))
      }
    );
  };


  useEffect(() => {
    dispatch(getUsersThunk());
    dispatch(getOrganizationsThunk());
  }, [dispatch, page, limit, sort?.sortBy, sort?.sortValue, search?.searchBy, search?.searchValue]);

  return (
    <>
      <Headder>
        <h1>Users list</h1>
        <Button type="primary" onClick={() => {
          setIsModalAdd(true)
          formAdd.resetFields();
        }}>
          Add User
        </Button>
        <Modal title="Add User" visible={isModalAdd}
          onCancel={() => {
            setIsModalAdd(false)
            formAdd.resetFields();
          }}
          footer={[]}>
          <FormUser form={formAdd} onFinish={onFinishAdd} organizations={organizations} />
        </Modal>
        <Modal title="Edit User" visible={isModalEdit}
          onCancel={() => {
            setIsModalEdit(false)
            formEdit.resetFields();
          }}
          footer={[]}>
          <FormUser form={formEdit} onFinish={onFinishEdit} userData={currentUser} organizations={organizations} />
        </Modal>
      </Headder>
      <Table
        bordered
        size="small"
        loading={userTableLoading}
        columns={[
          {
            title: "id",
            dataIndex: "id",
            key: "id",
            fixed: "left",
            sorter: (a, b) => a.id - b.id,
          },
          {
            title: "Organization count",
            dataIndex: "organizationsCount",
            key: "organizationsCount",
            fixed: "left",
            // ...getColumnSearchProps<UsersListItem>("organizationsCount"),
            sorter: (a, b) => a.organizationsCount - b.organizationsCount,
            render: (text) => <S.TableColumnSmall>{text}</S.TableColumnSmall>,
            // filterSearch: false,
            // filters: false,
          },
          {
            title: "Organizations",
            dataIndex: "organizationsNames",
            key: "organizationsNames",
            fixed: "left",
            // ...getColumnSearchProps<UsersListItem>("organizationsNames"),
            sorter: (a, b) => a.organizationsNames?.localeCompare(b.organizationsNames),
            render: (text) => <S.TableColumnSmall>{text}</S.TableColumnSmall>,
          },
          {
            title: "First name",
            dataIndex: "firstname",
            key: "firstname",
            fixed: "left",
            ...getColumnSearchProps<UsersListItem>("firstname"),
            sorter: (a, b) => a.firstname?.localeCompare(b.firstname),
            render: (text) => <S.TableColumnSmall>{text}</S.TableColumnSmall>,
          },
          {
            title: "Last name",
            dataIndex: "lastname",
            key: "lastname",
            fixed: "left",
            ...getColumnSearchProps<UsersListItem>("lastname"),
            sorter: (a, b) => a.lastname?.localeCompare(b.lastname),
            render: (text) => <S.TableColumnSmall>{text}</S.TableColumnSmall>,
          },
          {
            title: "E-mail",
            dataIndex: "email",
            key: "email",
            ...getColumnSearchProps<UsersListItem>("email"),
            sorter: (a, b) => a.email?.localeCompare(b.email),
            render: (text) => <S.TableColumnSmall>{text}</S.TableColumnSmall>,
          },
          {
            title: "Phone",
            dataIndex: "phone",
            key: "phone",
            ...getColumnSearchProps<UsersListItem>("phone"),
            sorter: (a, b) => a.phone?.localeCompare(b.phone),
            render: (text) => <S.TableColumnSmall>{text}</S.TableColumnSmall>,
          },
          {
            title: "Invitation Accepted",
            dataIndex: "isInvitationAccepted",
            key: "isInvitationAccepted",
            // ...getColumnSearchProps<UsersListItem>("isInvitationAccepted"),
            sorter: (a, b) => a.isInvitationAccepted?.localeCompare(b.isInvitationAccepted),
            render: (text) => <S.TableColumnSmall>{text}</S.TableColumnSmall>,
          },
          ...usersColumns,
          {
            dataIndex: "roles",
            title: "User roles",
            key: "roles",
            render: (cellValue: string[], { id }) => {
              return <UsersSelect cellValue={cellValue} id={id} />;
            },
          },
          {
            title: "Created",
            dataIndex: "createdAt",
            key: "createdAt",
            width: 200,
            sorter: (a, b) =>
              moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf(),
            render: (value: string) =>
              moment(value).format("MMM D, YYYY - h:mm A"),
          },
          {
            title: "Actions",
            key: "actions",
            width: "auto",
            render: (value) => (
              <S.Actions>
                <S.ActionItem>
                  <Button type="primary" onClick={() => {
                    setCurrentId(value)
                    setIsModalActions(true)
                  }}>
                    Action
                  </Button>
                  <Modal title="Actions" visible={isModalActions}
                    onCancel={() => setIsModalActions(false)}>
                    <S.Actions>
                      <S.ActionItem>
                        <Button type="primary" onClick={showModalEdit}>
                          Edit
                        </Button>
                      </S.ActionItem>
                      <S.ActionItem>
                        <Button type="primary" onClick={deleteUser}>
                          Delete
                        </Button>
                      </S.ActionItem>
                      {currentUser.source === "ADMIN" &&
                        < Button type="primary" onClick={sendInvitation}>
                          Invite
                        </Button>}
                    </S.Actions>
                  </Modal>
                </S.ActionItem>
              </S.Actions>
            ),
          },
        ]}
        dataSource={usersTable}
        sticky
        scroll={{ x: "max-content" }}
        rowKey="id"
        pagination={{ defaultPageSize: limit, showSizeChanger: true, pageSizeOptions: ['10', '20', '50'], total: totalCount }}
        onChange={handleChange}
      />
    </>
  );
};

export default Users;
