import styled from "styled-components";
import { Input } from "antd";

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & h1 {
    margin-bottom: 0;
  }
`;
export const Actions = styled.div`
  display: flex;
  align-items: center;
`;
export const ActionItem = styled.div`
  margin-right: 10px;
`;
export const OrderCell = styled.div`
  display: flex;
  align-items: center;
  column-gap: 15px;
`;

export const ArrowBlock = styled.div`
  width: 30px;
  cursor: pointer;
`;

export const SwitchBlock = styled.div`
  display: flex;
  justify-content: center;
`;

export const InputNumber = styled(Input)`
  width: 50px;
`;
export const AcceptOrder = styled.div`
  width: 30px;
  cursor: pointer;
`;
