import { createSlice } from "@reduxjs/toolkit";
import {editRolesThunk, getOrganizationsThunk, getUsersThunk} from ".//thunks";

const DEFAULT_PAGE = 1;
const DEFAULT_LIMIT = 20;

const initialState: any = {
  loading: false,
  list: [],
  organizations: [],
  page: DEFAULT_PAGE,
  limit: DEFAULT_LIMIT,
  search: {
    searchBy: null,
    searchValue: null,
  },
  sort: {
    sortBy: null,
    sortValue: null,
  },
};
export const users = createSlice({
  name: "users",
  initialState,
  reducers: {
    setActiveApplication(state, action) {
      // state.activeApplication = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setLimit(state, action) {
      state.limit = action.payload;
    },
    setSearch(state, action) {
      const { searchBy, searchValue } = action.payload;
      // if (!(searchBy && searchValue)) throw Error('action.payload searchBy & searchValue');
      state.search = { searchBy, searchValue };
    },
    setSort(state, action) {
      const { sortBy, sortValue } = action.payload;
      // if (!(sortBy && sortValue)) throw Error('action.payload sortBy & sortValue');
      state.sort = { sortBy, sortValue };
    },
    resetSearch(state) {
      state.search = { searchBy: null, searchValue: null };
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsersThunk.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUsersThunk.fulfilled, (state, { payload }) => {
        state.list = payload;
        state.loading = false;
      })
      .addCase(getUsersThunk.rejected, (state) => {
        state.loading = false;
      });

    builder
      .addCase(getOrganizationsThunk.fulfilled, (state, { payload }) => {
        state.organizations = payload;
      })

    builder
      .addCase(editRolesThunk.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(editRolesThunk.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(editRolesThunk.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  // resetBackgroundCheckState,
  setPage,
  setLimit,
  setSearch,
  setSort,
  resetSearch,
} = users.actions;
export default users.reducer;
