import { FormInstance } from "antd";

export const changeFormValue = (values: any, form: FormInstance) => {
  const [key] = Object.keys(values);
  form.setFieldsValue({
    [key]: values[key].replace(/[^0-9a-zA-z-$%()&*@#! ]/gi, ""),
  });
};

export const setAllValuesToFormData = (values: Record<string, any>) => {
  const formData = new FormData();
  Object.keys(values).forEach((key) => {
    formData.append(key, values[key]);
  });
  return formData;
};
