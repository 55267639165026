import { createSlice } from "@reduxjs/toolkit";
import {
  checkUniqueUrl,
  createBlogPost,
  editBlogPost,
  getPostById,
  getPosts,
} from "@/store/blog/thunks";

const initialState: any = {
  loading: false,
  createPostLoading: false,
  activePostLoading: false,
  activePost: null,
  isUniqueUrl: true,
  uniqueUrlChecking: false,
  list: [],
};
export const blog = createSlice({
  name: "blog",
  initialState,
  reducers: {
    setActivePost(state, action) {
      state.activePost = action.payload;
    },
    resetUniqueUrl(state) {
      state.isUniqueUrl = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPosts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPosts.fulfilled, (state, action) => {
        state.list = action.payload;
        state.loading = false;
      })
      .addCase(getPosts.rejected, (state) => {
        state.loading = false;
      });

    builder.addCase(getPostById.fulfilled, (state, action) => {
      state.activePost = action.payload;
    });

    builder
      .addCase(createBlogPost.pending, (state) => {
        state.createPostLoading = true;
      })
      .addCase(createBlogPost.fulfilled, (state) => {
        state.createPostLoading = false;
      })
      .addCase(createBlogPost.rejected, (state) => {
        state.createPostLoading = false;
      });

    builder
      .addCase(editBlogPost.pending, (state) => {
        state.activePostLoading = true;
      })
      .addCase(editBlogPost.fulfilled, (state) => {
        state.activePostLoading = false;
      })
      .addCase(editBlogPost.rejected, (state) => {
        state.activePostLoading = false;
      });

    builder
      .addCase(checkUniqueUrl.pending, (state) => {
        state.uniqueUrlChecking = true;
      })
      .addCase(checkUniqueUrl.fulfilled, (state, action) => {
        state.uniqueUrlChecking = false;
        state.isUniqueUrl = action.payload;
      })
      .addCase(checkUniqueUrl.rejected, (state) => {
        state.uniqueUrlChecking = false;
      });
  },
});
export const { setActivePost, resetUniqueUrl } = blog.actions;
export default blog.reducer;
