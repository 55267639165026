import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "@/axiosInstance";
import { notification } from "antd";
import { BlogPostItem } from "@/utils/types/blog";
import { setAllValuesToFormData } from "@/utils/helpers/functions";

export const getPosts = createAsyncThunk("Blog/getPosts", async () => {
  try {
    const { data } = await axiosInstance.get("/admin/blog");
    return data;
  } catch (error: any) {
    const {
      response: { data: errorMessage },
    } = error;
    notification.error({
      message: errorMessage,
      placement: "top",
    });
  }
});

export const getPostById = createAsyncThunk(
  "Blog/getPostById",
  async (postId: string) => {
    try {
      const { data } = await axiosInstance.get(`/admin/blog/${postId}`);
      return data;
    } catch (error: any) {
      const {
        response: { data: errorMessage },
      } = error;
      notification.error({
        message: errorMessage,
        placement: "top",
      });
    }
  }
);

export const createBlogPost = createAsyncThunk(
  "Blog/createBlogPost",
  async (values: BlogPostItem, { dispatch }) => {
    try {
      await axiosInstance.post("/admin/blog", setAllValuesToFormData(values), {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch(getPosts());
      notification.success({
        message: "Post successfully created",
        placement: "top",
      });
    } catch (error: any) {
      const {
        response: { data: errorMessage },
      } = error;
      notification.error({
        message: errorMessage,
        placement: "top",
      });
    }
  }
);

export const editBlogPost = createAsyncThunk(
  "Blog/editBlogPost",
  async (values: Record<string, any>, { dispatch }) => {
    try {
      await axiosInstance.patch("/admin/blog", setAllValuesToFormData(values), {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      dispatch(getPosts());
      notification.success({
        message: "Post successfully edited",
        placement: "top",
      });
    } catch (error: any) {
      const {
        response: { data: errorMessage },
      } = error;
      notification.error({
        message: errorMessage,
        placement: "top",
      });
    }
  }
);

export const deleteBlogPost = createAsyncThunk(
  "Blog/deleteBlogPost",
  async (id: number, { dispatch }) => {
    try {
      await axiosInstance.delete(`/admin/blog/${id}`);
      dispatch(getPosts());
    } catch (error: any) {
      const {
        response: { data: errorMessage },
      } = error;
      notification.error({
        message: errorMessage,
        placement: "top",
      });
    }
  }
);

export const checkUniqueUrl = createAsyncThunk(
  "Blog/checkUniqueUrl",
  async (url: string, { dispatch }) => {
    try {
      const { data } = await axiosInstance.post("/admin/url/check", {
        type: "Blog",
        url,
      });
      return !data;
    } catch (error: any) {
      const {
        response: { data: errorMessage },
      } = error;
      notification.error({
        message: errorMessage,
        placement: "top",
      });
    }
  }
);
