// no-check
import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "@/axiosInstance";
import { notification } from "antd";

const getUrlSort = (state: any) => {
  if (state?.sort?.sortBy) {
    return `&sortValue=${state?.sort?.sortValue}&sortBy=${state?.sort?.sortBy}`;
  }
  return '';
}

const getUrlSearch = (state: any) => {
  if (state?.search?.searchBy) {
    return `&searchValue=${state?.search?.searchValue}&searchBy=${state?.search?.searchBy}`;
  }
  return '';
}

export const getUsersThunk = createAsyncThunk("Users/getUsers", async (_, { getState }) => {
  try {
    const { users } = getState() as any;
    const sortUrl = getUrlSort(users);
    const searchUrl = getUrlSearch(users);

    const { data } = await axiosInstance.get(`/admin/users?page=${users.page}&limit=${users.limit}${sortUrl}${searchUrl}`);
    return data;
  } catch (error: any) {
    const {
      response: { data: errorMessage },
    } = error;
    notification.error({
      message: errorMessage,
      placement: "top",
    });
  }
});

export const getOrganizationsThunk = createAsyncThunk("Users/getOrganizations", async () => {
  try {
    const { data } = await axiosInstance.get("/organization");
    return data;
  } catch (error: any) {
    const {
      response: { data: errorMessage },
    } = error;
    notification.error({
      message: errorMessage,
      placement: "top",
    });
  }
});


export const editRolesThunk = createAsyncThunk(
  "Users/editRoles",
  async (
    { userId, roles }: { userId: number; roles: string[] },
    { dispatch }
  ) => {
    try {
      await axiosInstance.patch(`/admin/users/${userId}`, { roles });
      dispatch(getUsersThunk());
    } catch (error: any) {
      const {
        response: { data: errorMessage },
      } = error;
      notification.error({
        message: errorMessage,
        placement: "top",
      });
    }
  }
);


export const createUserThunk = createAsyncThunk(
    "Users/editRoles",
    async (params:any,
        { dispatch }
    ) => {
      try {
        let paramsClear = Object.fromEntries(Object.entries(params).filter(([_, v]) => v != ''));
        await axiosInstance.post(`/admin/users`, paramsClear);
        dispatch(getUsersThunk());
      } catch (error: any) {
        const {
          response: { data: errorMessage },
        } = error;
        notification.error({
          message: errorMessage,
          placement: "top",
        });
      }
    }
);

export const editUserThunk = createAsyncThunk(
    "Users/editRoles",
    async (params:any,
           { dispatch }
    ) => {
      try {
        let paramsClear = Object.fromEntries(Object.entries(params).filter(([_, v]) => v != ''));
        const {userId} = paramsClear
        await axiosInstance.patch(`/admin/users/${userId}`, paramsClear);
        dispatch(getUsersThunk());
      } catch (error: any) {
        const {
          response: { data: errorMessage },
        } = error;
        notification.error({
          message: errorMessage,
          placement: "top",
        });
      }
    }
);

export const deleteUserThunk = createAsyncThunk(
  "Users/deleteUser",
  async (userId: number, { dispatch }) => {
    try {
      await axiosInstance.delete(`/admin/users/${userId}`);
      dispatch(getUsersThunk());
    } catch (error: any) {
      const {
        response: { data: errorMessage },
      } = error;
      notification.error({
        message: errorMessage,
        placement: "top",
      });
    }
  }
);

export const sendInvitationThunk = createAsyncThunk(
    "Users/deleteUser",
    async (userId: any, { dispatch }) => {
      try {
        await axiosInstance.post(`/admin/users/${userId}/messages/email`);
        dispatch(getUsersThunk());
      } catch (error: any) {
        const {
          response: { data: errorMessage },
        } = error;
        notification.error({
          message: errorMessage,
          placement: "top",
        });
      }
    }
);

