import { FC, ReactElement } from "react";
import { Navigate } from "react-router-dom";

interface ProtectedRouteProps {
  isAuth: boolean;
  children: ReactElement;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children, isAuth }) => {
  if (isAuth) {
    return children;
  } else return <Navigate to="/login" replace />;
};

export default ProtectedRoute;
