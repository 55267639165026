import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { store, useAppDispatch, useAppSelector } from "./store/store";
import { Provider } from "react-redux";
import { axiosInstanceWrapper } from "./axiosInstance";
import { authSelector } from "@/store/auth/selectors";
import "antd/dist/antd.min.css";
import "react-quill/dist/quill.snow.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const AppWrapper: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isAuth } = useAppSelector(authSelector);
  useEffect(() => {
    axiosInstanceWrapper(dispatch);
  }, [dispatch]);
  return <App isAuth={isAuth} />;
};
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <AppWrapper />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);
