import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const LoginBlock = styled.div`
  padding: 10px;
  border: 1px solid #ccc;
`;
export const Header = styled.h1`
  text-align: center;
`;

export const FormBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 300px;
  grid-column-gap: 10px;
`;
export const ButtonBlock = styled.div`
  display: flex;
  justify-content: center;
`;

export const InputTitle = styled.div`
  padding-top: 5px;
  text-align: right;
`;
