import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 0 20px;
`;
export const Header = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
  align-items: center;
  padding-top: 10px;
`;
