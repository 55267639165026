import styled from "styled-components";
import { Link } from "react-router-dom";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
`;
export const LinkItem = styled(Link)`
  padding: 10px;
`;
