import React, { FC, memo, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { 
    statisticsListTableSelector, 
    statisticsListTableLoadingSelector 
} from "../../store/statisticslist/selectors";
import {
    getStatisticsList,
} from "../../store/statisticslist/thunks";
import { Table } from "antd";
import { statisticsListColumns } from "../../utils/helpers/columns";


interface StatisticsListProps {}

const StatisticsList: FC<StatisticsListProps> = () => {
    const dispatch = useAppDispatch();

    const statisticsList = useAppSelector(statisticsListTableSelector);
    const statisticsListLoading = useAppSelector(statisticsListTableLoadingSelector);

    useEffect(() => {
        dispatch(getStatisticsList());
    }, []);

    return (
        <>
            <h1>Statistics list</h1>
            <Table
                bordered
                size="small"
                loading={statisticsListLoading}
                dataSource={statisticsList}
                // onRow={({ email }) => onRowClick(email)}
                columns={statisticsListColumns}
                sticky
                scroll={{ x: "max-content" }}
                rowKey="id"
                pagination={{ defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['10', '20', '50'] }}
            />
        </>
    );
};

export default memo(StatisticsList);