import { createSlice } from "@reduxjs/toolkit";
import { signInUser } from "@/store/auth/thunks";

const initialState: any = {
  isAuth: false,
  loading: false,
};

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // setResetPhone: (state, action) => {
    //   state.resetPhone = action.payload;
    // },
  },

  extraReducers: (builder) => {
    builder
      .addCase(signInUser.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(signInUser.fulfilled, (state, action) => {
        state.loading = false;
        state.isAuth = action.payload;
      })
      .addCase(signInUser.rejected, (state, action) => {
        state.loading = false;
        state.isAuth = action.payload;
      });
  },
});

// export const {} = auth.actions;

export default auth.reducer;
