import { createSlice } from "@reduxjs/toolkit";
import {
  deleteMailListItem,
  getMailList,
  getMessagesByEmail,
  setResolvedMessage,
} from "@/store/maillist/thunks";

const initialState: any = {
  loading: false,
  loadingMessagesList: false,
  list: [],
  activeEmail: "",
  messagesByEmail: [],
};
export const maillist = createSlice({
  name: "maillist",
  initialState,
  reducers: {
    setActiveEmail(state, { payload }) {
      state.activeEmail = payload;
    },
    resetMessagesList(state) {
      state.activeEmail = "";
      state.messagesByEmail = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMailList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMailList.fulfilled, (state, { payload }) => {
        state.list = payload;
        state.loading = false;
      })
      .addCase(getMailList.rejected, (state) => {
        state.loading = false;
      });

    builder.addCase(deleteMailListItem.pending, (state) => {
      state.loading = true;
    });

    builder
      .addCase(getMessagesByEmail.pending, (state) => {
        state.loadingMessagesList = true;
      })
      .addCase(getMessagesByEmail.fulfilled, (state, { payload }) => {
        state.messagesByEmail = payload;
        state.loadingMessagesList = false;
      })
      .addCase(getMessagesByEmail.rejected, (state) => {
        state.loadingMessagesList = false;
      });

    builder
      .addCase(setResolvedMessage.pending, (state) => {
        state.loadingMessagesList = true;
      })
      .addCase(setResolvedMessage.fulfilled, (state) => {
        state.loadingMessagesList = false;
      })
      .addCase(setResolvedMessage.rejected, (state) => {
        state.loadingMessagesList = false;
      });
  },
});
export const { setActiveEmail, resetMessagesList } = maillist.actions;
export default maillist.reducer;
