import React, { memo, FC, useRef } from "react";
import { editRolesThunk } from "@/store/users/thunks";
import { UserRoles } from "@/utils/types/users";
import { Select } from "antd";
import { useAppDispatch } from "@/store/store";
const { Option } = Select;

interface UsersSelectProps {
  cellValue: string[];
  id: number;
}

const UsersSelect: FC<UsersSelectProps> = ({ cellValue, id }) => {
  const dispatch = useAppDispatch();
  const ref = useRef(null);
  return (
    <Select
      ref={ref}
      value={cellValue}
      mode="multiple"
      style={{ width: "200px" }}
      onChange={(values: any) => {
        //@ts-ignore
        ref.current?.blur();
        dispatch(editRolesThunk({ userId: id, roles: values }));
      }}
    >
      {Object.keys(UserRoles)
        .filter((key) => isNaN(Number(key)))
        .map((key) => (
          <Option value={key} key={`${key}`}>
            {key}
          </Option>
        ))}
    </Select>
  );
};

export default memo(UsersSelect);
