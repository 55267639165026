import styled from "styled-components";

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;

export const Headder = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const ActionItem = styled.div`
  margin-right: 10px;
`;

export const TableColumnSmall = styled.div<{ center?: boolean }>`
  width: 120px;
  text-align: ${({ center }) => (center ? "center" : "left")};
`;

export const TableColumnMedium = styled.div`
  width: 250px;
`;

export const TableColumnFullWidth = styled.div`
  width: 100%;
`;
