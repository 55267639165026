import { createSlice } from "@reduxjs/toolkit";
import {
  getStatisticsList,
} from "@/store/statisticslist/thunks";

const initialState: any = {
  loading: false,
  list: [],
};
export const statisticslist = createSlice({
  name: "statisticslist",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStatisticsList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getStatisticsList.fulfilled, (state, { payload }) => {
        state.list = payload;
        state.loading = false;
      })
      .addCase(getStatisticsList.rejected, (state) => {
        state.loading = false;
      });
  },
});
// export const { } = statisticslist.actions;
export default statisticslist.reducer;
