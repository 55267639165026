import axios from "axios";
// import { logoutUser } from "@/store/slices/User/thunks";
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});
axios.defaults.withCredentials = true;
axiosInstance.defaults.withCredentials = true;
export const axiosInstanceWrapper = (dispatch: any) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      config.headers!["Content-Type"] = "application/json";
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      if (error.response.status === 401) {
        console.log("Unauthorized");
        // if (window.location.pathname !== '/') {
        //   dispatch(logoutUser());
        //   // window.location.href = '/';
        // }
        // dispatch(setUserInfo(null));
      }
      return Promise.reject(error);
    }
  );
};
