import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "@/axiosInstance";
import { notification } from "antd";

// export const checkUserAuth = createAsyncThunk(
//   "Auth/checkUserAuth",
//   async () => {
//     try {
//       const { data } = await axiosInstance.get("/users/info");
//       localStorage.setItem("isLogin", "true");
//
//       return data;
//     } catch (error) {
//       localStorage.setItem("isLogin", "false");
//     }
//   }
// );

export const signInUser = createAsyncThunk(
  "Auth/signInUser",
  async (signInData: object, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post("/auth/signIn", signInData);
      if (data?.roles.find((role: string) => role === "ADMIN")) {
        return data;
      }
      notification.error({
        message: "This user is not Admin",
        placement: "top",
      });
      return rejectWithValue(false);
    } catch (error: any) {
      const {
        response: { data: errorMessage },
      } = error;
      notification.error({
        message: errorMessage,
        placement: "top",
      });
      return rejectWithValue(false);
    }
  }
);

export const logoutUser = createAsyncThunk("user/logoutUser", async () => {
  try {
    await axiosInstance.post("/auth/signOut");
  } catch (error: any) {
    const {
      response: { data: errorMessage },
    } = error;
    notification.error({
      message: errorMessage,
      placement: "top",
    });
  }
});
