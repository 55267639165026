import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import dashboard from "@/store/dashboard/reducer";
import users from "@/store/users/reducer";
import auth from "@/store/auth/reducer";
import blog from "@/store/blog/reducer";
import maillist from "@/store/maillist/reducer";
import faq from "@/store/faq/reducer";
import applications from "@/store/applications/reducer";
import statisticslist from "@/store/statisticslist/reducer";

export const store = configureStore({
  reducer: {
    auth,
    dashboard,
    users,
    blog,
    maillist,
    applications,
    faq,
    statisticslist,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
