import { RootState } from "@/store/store";
import { MailListItem, MessagesListItem } from "@/utils/types/maillist";

export const mailListTableSelector = (state: RootState): MailListItem[] =>
  state.maillist.list;

export const mailListTableLoadingSelector = (state: RootState): boolean =>
  state.maillist.loading;

export const messagesListLoadingSelector = (state: RootState): boolean =>
  state.maillist.loadingMessagesList;

export const messagesByEmailSelector = (state: RootState): MessagesListItem[] =>
  state.maillist.messagesByEmail;

export const activeEmailSelector = (state: RootState): string =>
  state.maillist.activeEmail;
