import React, { memo, FC, useEffect } from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Switch, Table, Tag } from "antd";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/store/store";
import {
  activePostLoadingSelector,
  postsListLoadingSelector,
  postsListSelector,
} from "@/store/blog/selectors";
import { deleteBlogPost, editBlogPost, getPosts } from "@/store/blog/thunks";
import * as S from "./styles";
import { postsColumns } from "@/utils/helpers/columns";
import { resetUniqueUrl, setActivePost } from "@/store/blog/reducer";
import OrderCell from "@/components/OrderCell";

interface BlogProps {}

const Blog: FC<BlogProps> = () => {
  const dispatch = useAppDispatch();
  const posts = useAppSelector(postsListSelector);
  const postsLoading = useAppSelector(postsListLoadingSelector);
  const editPostLoading = useAppSelector(activePostLoadingSelector);
  useEffect(() => {
    dispatch(setActivePost(null));
    dispatch(resetUniqueUrl());
    dispatch(getPosts());
  }, [dispatch]);

  return (
    <>
      <S.Header>
        <h1>Articles</h1>
        <Link to="/articles/add">
          <Button type="primary">Create article</Button>
        </Link>
      </S.Header>
      <Table
        bordered
        size="small"
        loading={postsLoading || editPostLoading}
        columns={[
          ...postsColumns,
          {
            title: "Order",
            dataIndex: "position",
            key: "position",
            width: 210,
            render: (position: number, { id }) => {
              return (
                <OrderCell
                  position={position}
                  length={posts.length}
                  onChangeOrder={(newPosition: number) => {
                    dispatch(editBlogPost({ position: newPosition, id }));
                  }}
                />
              );
            },
          },
          {
            title: "Is active?",
            dataIndex: "isActive",
            key: "isActive",
            width: 120,
            sorter: (a) => (a.isActive ? -1 : 1),
            render: (isActive: boolean, { id }) => {
              return (
                <S.SwitchBlock>
                  <Switch
                    checked={isActive}
                    defaultChecked={isActive}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={(checked) => {
                      dispatch(editBlogPost({ isActive: checked, id }));
                    }}
                  />
                </S.SwitchBlock>
              );
            },
          },
          {
            title: "Actions",
            key: "actions",
            width: 150,
            render: ({ id }) => (
              <S.Actions>
                <S.ActionItem>
                  <Link to={`/articles/edit/${id}`}>
                    <Tag color="processing" style={{ cursor: "pointer" }}>
                      Edit
                    </Tag>
                  </Link>
                </S.ActionItem>
                <S.ActionItem>
                  <Popconfirm
                    title="Sure to delete post?"
                    onConfirm={() => {
                      dispatch(deleteBlogPost(id));
                    }}
                  >
                    <Tag color="error" style={{ cursor: "pointer" }}>
                      Delete
                    </Tag>
                  </Popconfirm>
                </S.ActionItem>
              </S.Actions>
            ),
          },
        ]}
        dataSource={posts}
        sticky
        scroll={{ x: "max-content" }}
        rowKey="id"
      />
    </>
  );
};

export default memo(Blog);
