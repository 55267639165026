import React, { memo, FC, useEffect } from "react";
import { Button, Popconfirm, Switch, Table, Tag } from "antd";
import { faqColumns } from "@/utils/helpers/columns";
import * as S from "./styles";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { Link } from "react-router-dom";
import OrderCell from "@/components/OrderCell";
import { deleteFaq, editFaq, getFaqs } from "@/store/faq/thunks";
import {
  faqListSelector,
  faqsListLoadingSelector,
} from "@/store/faq/selectors";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { setActiveFaq } from "@/store/faq/reducer";

interface FAQProps {}

const FAQ: FC<FAQProps> = () => {
  const dispatch = useAppDispatch();
  const faqs = useAppSelector(faqListSelector);
  const loading = useAppSelector(faqsListLoadingSelector);

  useEffect(() => {
    dispatch(setActiveFaq(null));
    dispatch(getFaqs());
  }, [dispatch]);

  return (
    <>
      <S.Header>
        <h1>FAQ</h1>
        <Link to="/faq/add">
          <Button type="primary">Create question</Button>
        </Link>
      </S.Header>

      <Table
        size="small"
        loading={loading}
        columns={[
          ...faqColumns,
          {
            title: "Order",
            dataIndex: "position",
            key: "position",
            width: 210,
            render: (position: number, { id }) => {
              return (
                <OrderCell
                  position={position}
                  length={faqs.length}
                  onChangeOrder={(newPosition: number) => {
                    dispatch(editFaq({ position: newPosition, id }));
                  }}
                />
              );
            },
          },
          {
            title: "Is active?",
            dataIndex: "isActive",
            key: "isActive",
            width: 120,
            sorter: (a) => (a.isActive ? -1 : 1),
            render: (isActive: boolean, { id }) => {
              return (
                <S.SwitchBlock>
                  <Switch
                    defaultChecked={isActive}
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    onChange={(checked) => {
                      console.log({ isActive, id });
                      dispatch(editFaq({ isActive: checked, id }));
                    }}
                  />
                </S.SwitchBlock>
              );
            },
          },
          {
            title: "Actions",
            key: "actions",
            width: 200,
            render: ({ id }) => (
              <S.Actions>
                <S.ActionItem>
                  <Link to={`/faq/edit/${id}`}>
                    <Tag color="processing" style={{ cursor: "pointer" }}>
                      Edit
                    </Tag>
                  </Link>
                </S.ActionItem>
                <S.ActionItem>
                  <Popconfirm
                    title="Sure to delete question?"
                    onConfirm={() => {
                      dispatch(deleteFaq(id));
                    }}
                  >
                    <Tag color="error" style={{ cursor: "pointer" }}>
                      Delete
                    </Tag>
                  </Popconfirm>
                </S.ActionItem>
              </S.Actions>
            ),
          },
        ]}
        dataSource={faqs}
        sticky
        scroll={{ x: "max-content" }}
        rowKey="id"
      />
    </>
  );
};

export default memo(FAQ);
