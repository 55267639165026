// no-check
import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "@/axiosInstance";
import { notification } from "antd";


export const getApplicationsThunk = createAsyncThunk("Applications/getApplicationsThunk", async (_, { getState }) => {
  try {
    const { data } = await axiosInstance.get(`/admin/users-groups`);
    return data;
  } catch (error: any) {
    const {
      response: { data: errorMessage },
    } = error;
    notification.error({
      message: errorMessage,
      placement: "top",
    });
  }
});








