import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "@/axiosInstance";
import { notification } from "antd";

export const getMailList = createAsyncThunk(
  "Maillist/getMailList",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("/email");
      return data;
    } catch (error: any) {
      const {
        response: { data: errorMessage },
      } = error;
      notification.error({
        message: errorMessage,
        placement: "top",
      });
      return rejectWithValue("");
    }
  }
);

export const deleteMailListItem = createAsyncThunk(
  "Maillist/deleteMailListItem",
  async (email: string, { dispatch, rejectWithValue }) => {
    try {
      await axiosInstance.delete(`/email/${email}`);
      dispatch(getMailList());
    } catch (error: any) {
      const {
        response: { data: errorMessage },
      } = error;
      notification.error({
        message: errorMessage,
        placement: "top",
      });
      return rejectWithValue("");
    }
  }
);

export const getMessagesByEmail = createAsyncThunk(
  "Maillist/getMessagesByEmail",
  async (email: string, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(`/email/${email}/message`);
      return data;
    } catch (error: any) {
      const {
        response: { data: errorMessage },
      } = error;
      notification.error({
        message: errorMessage,
        placement: "top",
      });
      return rejectWithValue("");
    }
  }
);

export const setResolvedMessage = createAsyncThunk(
  "Maillist/setResolvedMessage",
  async (
    {
      isResolved,
      id,
      email,
    }: { isResolved: boolean; id: string; email: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const { data } = await axiosInstance.patch(`/email/message/${id}`, {
        isResolved,
      });
      dispatch(getMessagesByEmail(email));
      return data;
    } catch (error: any) {
      const {
        response: { data: errorMessage },
      } = error;
      notification.error({
        message: errorMessage,
        placement: "top",
      });
      return rejectWithValue("");
    }
  }
);
