import React, { memo, FC, useState, useEffect } from "react";
import * as S from "@/pages/articles/styles";
import {
  CheckCircleTwoTone,
  DownSquareTwoTone,
  UpSquareTwoTone,
} from "@ant-design/icons";

interface BlogOrderCellProps {
  position: number;
  length: number;
  onChangeOrder: (position: number) => void;
}

const OrderCell: FC<BlogOrderCellProps> = ({
  position,
  length,
  onChangeOrder,
}) => {
  const [inputValue, setInputValue] = useState(0);
  const [changed, setChanged] = useState(false);

  const changeInputValue = (evt: any) => {
    setInputValue(evt.target.value.replace(/[^0-9]/gi, ""));
    setChanged(true);
  };

  const changePosition = (pos: number) => {
    setChanged(false);
    onChangeOrder(pos);
  };

  useEffect(() => {
    setInputValue(position);
  }, [position]);

  return (
    <S.OrderCell>
      <S.ArrowBlock onClick={() => changePosition(position + 1)}>
        {position !== length && <DownSquareTwoTone style={{ fontSize: 27 }} />}
      </S.ArrowBlock>
      <S.ArrowBlock onClick={() => changePosition(position - 1)}>
        {position !== 1 && <UpSquareTwoTone style={{ fontSize: 27 }} />}
      </S.ArrowBlock>
      <S.InputNumber
        value={inputValue}
        onChange={changeInputValue}
        maxLength={3}
      />
      <S.AcceptOrder onClick={() => changePosition(Number(inputValue))}>
        {changed && <CheckCircleTwoTone style={{ fontSize: 27 }} />}
      </S.AcceptOrder>
    </S.OrderCell>
  );
};

export default memo(OrderCell);
