import { RootState } from "@/store/store";
import { FAQItem } from "@/utils/types/faq";

export const faqListSelector = (state: RootState): any => state.faq.list;

export const faqsListLoadingSelector = (state: RootState): boolean =>
  state.faq.loading;

export const activeFaqSelector = (state: RootState): FAQItem =>
  state.faq.activeFaq;

export const activeFaqLoadingSelector = (state: RootState): boolean =>
  state.faq.activeFaqLoading;

export const createFaqLoadingSelector = (state: RootState): boolean =>
  state.faq.createFaqLoading;
