import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "@/axiosInstance";
import { notification } from "antd";

export const getStatisticsList = createAsyncThunk(
  "StatisticsList/getStatisticsList",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get("/statistics/admin");
      return data;
    } catch (error: any) {
      const {
        response: { data: errorMessage },
      } = error;
      notification.error({
        message: errorMessage,
        placement: "top",
      });
      return rejectWithValue("");
    }
  }
);