import React, { memo, FC, useState, useEffect } from "react";
import moment from "moment";
import { Button, Form, Input, Switch } from "antd";
import { changeFormValue } from "@/utils/helpers/functions";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { v4 as uuid } from "uuid";

import { createFaq, editFaq, getFaqById } from "@/store/faq/thunks";
import { useNavigate, useParams } from "react-router-dom";
import Editor from "@/components/Editor";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import * as S from "./styles";
import {
  activeFaqLoadingSelector,
  activeFaqSelector,
  createFaqLoadingSelector,
} from "@/store/faq/selectors";

interface AddBlogItemProps {
  isEdit?: boolean;
}

const AddFAQItem: FC<AddBlogItemProps> = ({ isEdit }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { faqId } = useParams();
  const dispatch = useAppDispatch();
  const [editor, setEditor] = useState("");
  const faq = useAppSelector(activeFaqSelector);
  const loading = useAppSelector(activeFaqLoadingSelector);
  const createFaqLoading = useAppSelector(createFaqLoadingSelector);

  const submitForm = async (values: any) => {
    if (isEdit && faqId) {
      await dispatch(editFaq({ ...values, id: faqId, body: editor }));
      dispatch(getFaqById(faqId));
      return;
    }
    dispatch(createFaq({ title: values.title, body: editor, url: uuid() }));
    navigate("/faq");
  };
  useEffect(() => {
    if (isEdit && faqId) {
      dispatch(getFaqById(faqId));
    }
  }, [dispatch, isEdit, faqId]);
  useEffect(() => {
    if (faq) {
      const { body, title } = faq;
      setEditor(body);
      form.setFieldsValue({ title });
    }
  }, [form, faq]);

  return (
    <>
      <S.Header>
        <h1>{isEdit ? "Save" : "Create"} question</h1>
        {!!faq && (
          <S.Header>
            <S.ActiveSwitchBlock>
              <b>Active:</b>
              <Switch
                disabled
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={faq.isActive}
              />
            </S.ActiveSwitchBlock>
            <div>
              <b>Created:</b>{" "}
              {moment(faq.createdAt).format("MMM D, YYYY - h:mm A")}
            </div>
            <div>
              <b>Updated:</b>{" "}
              {moment(faq.updatedAt).format("MMM D, YYYY - h:mm A")}
            </div>
          </S.Header>
        )}
      </S.Header>

      <S.FormWrapper
        form={form}
        onFinish={submitForm}
        autoComplete="off"
        onValuesChange={(values: any) => changeFormValue(values, form)}
        colon={false}
      >
        <Form.Item
          label="Question"
          name="title"
          rules={[{ required: true, message: "Please input question" }]}
          style={{ marginBottom: 10 }}
        >
          <Input
            onChange={({ target: { value: title } }) => {
              form.setFieldsValue({
                title,
              });
            }}
          />
        </Form.Item>
        <S.Editor>
          <h3>Answer</h3>
          <Editor
            defaultValue={faq?.body}
            editor={editor}
            setEditor={setEditor}
          />
        </S.Editor>
        <S.Button>
          <Form.Item>
            <Button
              disabled={false}
              type="primary"
              htmlType="submit"
              loading={loading || createFaqLoading}
            >
              {isEdit ? "Save" : "Create"} question
            </Button>
          </Form.Item>
        </S.Button>
      </S.FormWrapper>
    </>
  );
};

export default memo(AddFAQItem);
