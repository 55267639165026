import React, { memo, FC } from "react";
import * as S from "@/components/Menu/styles";

interface MenuProps {}

const Menu: FC<MenuProps> = () => {
  return (
    <S.Wrapper>
      <S.LinkItem to={"/"}>Dashboard</S.LinkItem>
      <S.LinkItem to={"/mail-list"}>Mail list</S.LinkItem>
      <S.LinkItem to={"/users"}>Users</S.LinkItem>
      <S.LinkItem to={"applications"}>Applications</S.LinkItem>
      <S.LinkItem to={"/articles"}>Articles</S.LinkItem>
      <S.LinkItem to={"/faq"}>FAQ</S.LinkItem>
      <S.LinkItem to={"/statistics-list"}>Statistics</S.LinkItem>
    </S.Wrapper>
  );
};

export default memo(Menu);
