// @ts-nocheck
import React, { FC, useEffect } from "react";
import {Table} from "antd";
import {useAppDispatch, useAppSelector} from "@/store/store";
import * as S from "@/pages/users/styles";
import {getColumnSearchProps} from "@/utils/helpers/columns";
import {UsersListItem} from "@/utils/types/users";
import {Headder} from "@/pages/users/styles";
import {applicationsTableLoadingSelector, applicationsTableSelector} from "../../store/applications/selectors";
import {getApplicationsThunk} from "../../store/applications/thunks";


const Applications: FC = () => {
  const dispatch = useAppDispatch();
  const applicationsTable = useAppSelector(applicationsTableSelector);
  const applicationsTableLoading = useAppSelector(applicationsTableLoadingSelector);


  useEffect(() => {
    dispatch(getApplicationsThunk())
  }, [dispatch]);

  return (
    <>
      <Headder>
        <h1>Applications list</h1>
      </Headder>
      <Table
        bordered
        size="small"
        loading={applicationsTableLoading}
        columns={[
          {
            title: "Group name",
            dataIndex: "groupName",
            key: "groupName",
            fixed: "left",
            sorter: (a, b) => a.id - b.id,
          },
          {
            title: "Group status",
            dataIndex: "groupStatus",
            key: "groupStatus",
            fixed: "left",
            ...getColumnSearchProps<UsersListItem>("groupStatus"),
            sorter: (a, b) => a.groupStatus?.localeCompare(b.groupStatus),
            render: (text) => <S.TableColumnSmall>{text}</S.TableColumnSmall>,
          },
          {
            title: "Application sender name",
            dataIndex: "applicationSenderName",
            key: "applicationSenderName",
            fixed: "left",
            ...getColumnSearchProps<UsersListItem>("applicationSenderName"),
            sorter: (a, b) => a.applicationSenderName?.localeCompare(b.applicationSenderName),
            render: (text) => <S.TableColumnSmall>{text}</S.TableColumnSmall>,
          },
          {
            title: "Organization name",
            dataIndex: "organizationName",
            key: "organizationName",
            ...getColumnSearchProps<UsersListItem>("organizationName"),
            sorter: (a, b) => a.organizationName?.localeCompare(b.organizationName),
            render: (text) => <S.TableColumnSmall>{text}</S.TableColumnSmall>,
          },
          {
            title: "Property address",
            dataIndex: "propertyAddress",
            key: "propertyAddress",
            ...getColumnSearchProps<UsersListItem>("propertyAddress"),
            sorter: (a, b) => a.propertyAddress?.localeCompare(b.propertyAddress),
            render: (text) => <S.TableColumnSmall>{text}</S.TableColumnSmall>,
          },
          {
            title: "Applicant name",
            dataIndex: "applicantName",
            key: "applicantName",
            ...getColumnSearchProps<UsersListItem>("applicantName"),
            sorter: (a, b) => a.applicantName?.localeCompare(b.applicantName),
            render: (text) => <S.TableColumnSmall>{text}</S.TableColumnSmall>,
          },
          {
            title: "Amount paid",
            dataIndex: "amountPaid",
            key: "amountPaid",
            ...getColumnSearchProps<UsersListItem>("amountPaid"),
            sorter: (a, b) => a.amountPaid?.localeCompare(b.amountPaid),
            render: (text) => <S.TableColumnSmall>{text}</S.TableColumnSmall>,
          },
          {
            dataIndex: "status",
            title: "Status",
            key: "status",
            ...getColumnSearchProps<UsersListItem>("status"),
            sorter: (a, b) => a.status?.localeCompare(b.status),
            render: (text) => <S.TableColumnSmall>{text}</S.TableColumnSmall>,
          },
          {
            title: "Status date",
            dataIndex: "statusDate",
            key: "statusDate",
            ...getColumnSearchProps<UsersListItem>("statusDate"),
            sorter: (a, b) => a.statusDate?.localeCompare(b.statusDate),
            render: (text) => <S.TableColumnSmall>{text}</S.TableColumnSmall>,
          },
        ]}
        dataSource={applicationsTable}
        sticky
        scroll={{x: "max-content"}}
        rowKey="id"
      />
    </>
  );
};

export default Applications;
