import styled from "styled-components";
import { Form } from "antd";

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
`;
export const ActiveSwitchBlock = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

export const FormWrapper = styled(Form)`
  padding-bottom: 10px;
`;

export const Editor = styled.div`
  & .ql-tooltip.ql-editing {
    z-index: 1;
  }
`;

export const Button = styled.div`
  margin-top: 20px;
`;
