import React, { memo, FC, useEffect, useState } from "react";
import {
  deleteMailListItem,
  getMailList,
  getMessagesByEmail,
  setResolvedMessage,
} from "@/store/maillist/thunks";
import { useAppDispatch, useAppSelector } from "@/store/store";
import { Popconfirm, Table, Tag, Modal, Skeleton, Switch } from "antd";
import { mailListColumns, messagesListColumns } from "@/utils/helpers/columns";
import * as S from "@/pages/articles/styles";
import * as M from "./styles";
import {
  activeEmailSelector,
  mailListTableLoadingSelector,
  mailListTableSelector,
  messagesByEmailSelector,
  messagesListLoadingSelector,
} from "@/store/maillist/selectors";
import { resetMessagesList, setActiveEmail } from "@/store/maillist/reducer";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

interface MailListProps {}

const MailList: FC<MailListProps> = () => {
  const dispatch = useAppDispatch();

  const [isMessagesListOpen, setMessagesListOpen] = useState(false);

  const mailList = useAppSelector(mailListTableSelector);
  const mailListLoading = useAppSelector(mailListTableLoadingSelector);
  const messagesListLoading = useAppSelector(messagesListLoadingSelector);
  const messagesByEmail = useAppSelector(messagesByEmailSelector);
  const activeEmail = useAppSelector(activeEmailSelector);

  const onConfirmDelete = (
    evt?: React.MouseEvent<HTMLElement>,
    email?: string
  ) => {
    evt?.stopPropagation();
    dispatch(deleteMailListItem(email ?? ""));
  };

  const onRowClick = (email: string) => ({
    onClick: () => {
      dispatch(setActiveEmail(email));
      dispatch(getMessagesByEmail(email));
      setMessagesListOpen(true);
    },
  });

  useEffect(() => {
    dispatch(getMailList());
  }, [dispatch]);

  return (
    <>
      <Modal
        title="Messages list"
        visible={isMessagesListOpen}
        footer={null}
        onCancel={() => {
          setMessagesListOpen(false);
          dispatch(resetMessagesList());
          dispatch(getMailList());
        }}
        width={1000}
        bodyStyle={{ padding: "5px 20px 5px 25px" }}
      >
        {messagesListLoading ? (
          <Skeleton.Input active block size={"large"} />
        ) : (
          <h2>{activeEmail}</h2>
        )}
        <M.Table>
          <Table
            bordered
            size="small"
            loading={messagesListLoading}
            dataSource={messagesByEmail}
            columns={[
              ...messagesListColumns,
              {
                title: "Is resolved?",
                dataIndex: "isResolved",
                key: "isResolved",
                width: 120,
                sorter: (a) => (a.isResolved ? -1 : 1),
                render: (isResolved: boolean, { id, email }) => {
                  return (
                    <S.SwitchBlock>
                      <Switch
                        checked={isResolved}
                        defaultChecked={isResolved}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        onChange={(checked) => {
                          dispatch(
                            setResolvedMessage({
                              isResolved: checked,
                              id,
                              email,
                            })
                          );
                        }}
                      />
                    </S.SwitchBlock>
                  );
                },
              },
            ]}
            sticky
            rowKey="id"
          />
        </M.Table>
      </Modal>
      <h1>Mail list</h1>
      <Table
        bordered
        size="small"
        loading={mailListLoading}
        dataSource={mailList}
        onRow={({ email }) => onRowClick(email)}
        columns={[
          ...mailListColumns,
          {
            title: "Actions",
            key: "actions",
            width: 80,
            render: (_, { email }) => (
              <S.Actions>
                <S.ActionItem>
                  <Popconfirm
                    title="Sure to delete email?"
                    onConfirm={(evt) => onConfirmDelete(evt, email)}
                    onCancel={(evt) => evt?.stopPropagation()}
                  >
                    <Tag
                      color="error"
                      style={{ cursor: "pointer" }}
                      onClick={(evt) => evt.stopPropagation()}
                    >
                      Delete
                    </Tag>
                  </Popconfirm>
                </S.ActionItem>
              </S.Actions>
            ),
          },
        ]}
        sticky
        scroll={{ x: "max-content" }}
        rowKey="email"
      />
    </>
  );
};

export default memo(MailList);
