import React, {memo, FC, useEffect} from "react";
import {AutoComplete, Button, Form, Input, Select} from "antd";
import { map } from 'lodash';

interface FormUserProps {
  form: any,
  onFinish: any,
  userData?: any,
  organizations: any,
}

const { Option } = Select;

const FormUser: FC<FormUserProps> = ({form, onFinish, userData, organizations }) => {

  const initialValues = {
    organizationName: userData?.organizationName || '',
    organizationId: userData?.organizationId || '',
    email: userData?.email || '',
    phone: userData?.phone || '',
    password: userData?.password || '',
    firstname: userData?.firstname || '',
    lastname: userData?.lastname || '',
    middlename: userData?.middlename || '',
    dob: userData?.dob || '',
    roles: userData?.roles || [],
  };

  const dataOrg = map(organizations, org => ({
    value: org.name,
    label: org.name,
    id: org.id
  }))

  const handleSelect = (value) => {
    const selectedOption = dataOrg.find(opt => opt.value === value);
    const newValues = {
      ...form.getFieldsValue(),
      organizationId: selectedOption.id
    };
    form.setFieldsValue(newValues);
  };

  const filterOptions = (inputValue, option) => {
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  };

  useEffect(() => {
    form.resetFields();
  }, [userData]);

  return (
    <Form
      form={form}
      initialValues={initialValues}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      onFinish={onFinish}
    >
      <Form.Item
        label="Email"
        name="email"
        rules={!userData && [{ required: true, message: 'Please input email!' }]}
      >
        <Input value={''}/>
      </Form.Item>
      <Form.Item
        label="Phone"
        name="phone"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Password"
        name="password"
        rules={!userData && [{ required: true, message: 'Please input password!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="First name"
        name="firstname"
        rules={!userData && [{ required: true, message: 'Please input first name!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Last name"
        name="lastname"
        rules={!userData && [{ required: true, message: 'Please input last name!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Middle name"
        name="middlename"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Date of birth"
        name="dob"
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="User's role"
        name="roles"
        rules={!userData && [{ required: true, message: 'Please input role!' }]}
      >
        <Select
          placeholder="Select a User's role"
          mode="multiple"
          allowClear
        >
          <Option value={"LANDLORD"}>LANDLORD</Option>
          {userData && <Option value={"AGENT"}>AGENT</Option>}
          {userData && <Option value={"TENANT"}>TENANT</Option>}
          {userData && <Option value={"ADMIN"}>ADMIN</Option>}
        </Select>
      </Form.Item>
      <Form.Item
        label="Name organization"
        name="organizationName"
      >
        <AutoComplete
          options={dataOrg}
          onSelect={handleSelect}
          filterOption={filterOptions}
        />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default memo(FormUser);
