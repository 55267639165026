import { ColumnsType, ColumnType } from "antd/es/table";
import { DashboardUser } from "@/utils/types/dashboard";
import { BlogPostItem } from "@/utils/types/blog";
import { UsersListItem } from "@/utils/types/users";
import moment from "moment";
import * as S from "@/pages/users/styles";
import { FAQItem } from "@/utils/types/faq";
import { Button, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import React from "react";
import { MailListItem, MessagesListItem } from "@/utils/types/maillist";
import { useAppDispatch } from "@/store/store";
import { 
  resetSearch,
} from "@/store/users/reducer";
import { StatisticsListItem } from "../types/statisticslist";

export const getColumnSearchProps = <T, >(
  dataIndex: any
): ColumnType<T> => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const dispatch = useAppDispatch();
    return (
      <div style={{ padding: 8 }}>
        <Input
          value={selectedKeys[0]}
          onChange={({ target: { value } }) =>
            setSelectedKeys(value ? [value] : [])
          }
          onPressEnter={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setSelectedKeys(
              selectedKeys.map((item) =>
                typeof item !== "number" ? item.trim() : item
              )
            );
            confirm();
          }}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => {
              setSelectedKeys(
                selectedKeys.map((item) =>
                  typeof item !== "number" ? item.trim() : item
                )
              );
              confirm();
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              setSelectedKeys([""]);
              clearFilters && clearFilters();
              confirm();
              dispatch(resetSearch());
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    );
  },
  filterIcon: (filtered: boolean) => (
    <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  ),
  onFilter: (value, record) =>
    record[dataIndex]
      //@ts-ignore
      ?.toString()
      .toLowerCase()
      .includes((value as string).toLowerCase()),
});

export const dashboardColumns: ColumnsType<DashboardUser> = [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Total users",
    dataIndex: "totalUsers",
    key: "totalUsers",
  },
  {
    title: "Users Last Day",
    dataIndex: "userLastDay",
    key: "userLastDay",
  },
  {
    title: "Users Last Month",
    dataIndex: "userLastMonth",
    key: "userLastMonth",
  },
];
export const usersColumns: ColumnsType<UsersListItem> = [
  {
    title: "Properties",
    dataIndex: "propertiesCount",
    key: "properties",
    sorter: (a, b) => a.propertiesCount - b.propertiesCount,
    render: (value: string) => {
      return <S.TableColumnSmall>{value}</S.TableColumnSmall>;
    },
  },
  {
    title: "Units",
    dataIndex: "unitsCount",
    key: "units",
    sorter: (a, b) => a.unitsCount - b.unitsCount,
    render: (value: string) => {
      return <S.TableColumnSmall>{value}</S.TableColumnSmall>;
    },
  },
  {
    title: "Source",
    dataIndex: "source",
    key: "source",
    sorter: (a, b) => a.source?.localeCompare(b.source),
    render: (value: string) => {
      return <S.TableColumnSmall>{value}</S.TableColumnSmall>;
    },
  },
  {
    title: "Applications",
    dataIndex: "applicationInvitesCount",
    key: "applications",
    sorter: (a, b) => a.applicationInvitesCount - b.applicationInvitesCount,
    render: (value: string) => {
      return <S.TableColumnSmall>{value}</S.TableColumnSmall>;
    },
  },
];

export const postsColumns: ColumnsType<BlogPostItem> = [
  {
    title: "Title",
    dataIndex: "title",
    key: "title",
    width: 400,
    sorter: (a, b) => a.title?.localeCompare(b.title),
  },
  {
    title: "URL",
    dataIndex: "url",
    key: "url",
    sorter: (a, b) => a.url?.localeCompare(b.url),
    onCell: () => ({
      style: {
        maxWidth: 300,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      },
    }),
  },
  {
    title: "Created",
    dataIndex: "createdAt",
    key: "createdAt",
    width: 220,
    sorter: (a, b) =>
      moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf(),
    render: (value: string) => moment(value).format("MMM D, YYYY - h:mm A"),
  },
  {
    title: "Author",
    dataIndex: "creatorFirstName",
    key: "creatorFirstName",
    width: 170,
    sorter: (a, b) => a.creatorFirstName?.localeCompare(b.creatorFirstName),
    render: (_, { creatorFirstName, creatorLastName }) =>
      `${creatorFirstName} ${creatorLastName}`,
  },
];

export const faqColumns: ColumnsType<FAQItem> = [
  {
    title: "Questions",
    dataIndex: "title",
    key: "title",
    width: 600,
  },
  {
    title: "Created",
    dataIndex: "createdAt",
    key: "createdAt",
    width: 200,
    render: (value: string) => moment(value).format("MMM D, YYYY - h:mm A"),
  },
];

export const mailListColumns: ColumnsType<MailListItem> = [
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    width: 250,
    fixed: "left",
    ...getColumnSearchProps<MailListItem>("email"),
    sorter: (a, b) => a.email?.localeCompare(b.email),
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: 250,
    ...getColumnSearchProps<MailListItem>("name"),
    sorter: (a, b) => a.name?.localeCompare(b.name),
  },
  {
    title: "Message",
    dataIndex: "lastMessage",
    key: "lastMessage",
    width: 400,
    ...getColumnSearchProps<MailListItem>("lastMessage"),
    sorter: (a, b) => a.lastMessage?.localeCompare(b.lastMessage),
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
    width: 150,
    ...getColumnSearchProps<MailListItem>("phone"),
  },
  {
    title: "Last message",
    dataIndex: "lastMessageCreatedAt",
    key: "lastMessageCreatedAt",
    width: 200,
    sorter: (a, b) =>
      moment(a.lastMessageCreatedAt).valueOf() -
      moment(b.lastMessageCreatedAt).valueOf(),
    render: (value: string) => moment(value).format("MMM D, YYYY - h:mm A"),
  },
  {
    title: "Resolved/Total",
    dataIndex: "resolved",
    key: "resolved",
    width: 120,
  },
];

export const statisticsListColumns: ColumnsType<StatisticsListItem> = [
  // {
  //   title: "Id",
  //   dataIndex: "id",
  //   key: "id",
  //   width: 250,
  //   fixed: "left",
  //   ...getColumnSearchProps<MailListItem>("StatisticsListItem"),
  //   sorter: (a, b) => a.id?.localeCompare(b.id),
  // },
  {
    title: "Client",
    dataIndex: "client",
    key: "client",
    width: 250,
    fixed: 'left',
    ...getColumnSearchProps<StatisticsListItem>("client"),
    sorter: (a, b) => a.client?.localeCompare(b.client),
  },
  {
    title: "Call",
    dataIndex: "call",
    key: "call",
    width: 400,
    ...getColumnSearchProps<StatisticsListItem>("call"),
    sorter: (a, b) => a.call?.localeCompare(b.call),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: 150,
    ...getColumnSearchProps<StatisticsListItem>("status"),
    sorter: (a, b) => b.status - a.status,
  },
  {
    title: "Message",
    dataIndex: "message",
    key: "message",
    width: 200,
  },
  {
    title: "Begin datetime",
    dataIndex: "beginDatetime",
    key: "beginDatetime",
    width: 200,
    sorter: (a, b) =>
      moment(a.beginDatetime).valueOf() -
      moment(b.beginDatetime).valueOf(),
    render: (value: string) => moment(value).format("MMM D, YYYY - h:mm A"),
  },
  {
    title: "End datetime",
    dataIndex: "endDatetime",
    key: "endDatetime",
    width: 200,
    sorter: (a, b) =>
      moment(a.endDatetime).valueOf() -
      moment(b.endDatetime).valueOf(),
    render: (value: string) => moment(value).format("MMM D, YYYY - h:mm A"),
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
    width: 150,
    ...getColumnSearchProps<StatisticsListItem>("address"),
    // sorter: (a, b) => a?.address?.localeCompare(b?.address),
  },
  {
    title: "Unit",
    dataIndex: "unit",
    key: "unit",
    width: 150,
    ...getColumnSearchProps<StatisticsListItem>("unit"),
    // sorter: (a, b) => a?.address?.localeCompare(b?.address),
  },
  {
    title: "Bedrooms",
    dataIndex: "bedrooms",
    key: "bedrooms",
    width: 150,
    ...getColumnSearchProps<StatisticsListItem>("bedrooms"),
    // sorter: (a, b) => a?.address?.localeCompare(b?.address),
  },
  {
    title: "Price",
    dataIndex: "price",
    key: "price",
    width: 150,
    ...getColumnSearchProps<StatisticsListItem>("price"),
    // sorter: (a, b) => a?.address?.localeCompare(b?.address),
  },
  {
    title: "Value",
    dataIndex: "value",
    key: "value",
    width: 150,
    render: (value: any) => {
      try {
        if (typeof value === "string") return value;
        return JSON.stringify(value);
      } catch {
        return 'error';
      }
    },
  },
  {
    title: "Create datetime",
    dataIndex: "createdAt",
    key: "createdAt",
    width: 200,
    sorter: (a, b) =>
      moment(a.createdAt).valueOf() -
      moment(b.createdAt).valueOf(),
    render: (value: string) => moment(value).format("MMM D, YYYY - h:mm A"),
  },
];

export const messagesListColumns: ColumnsType<MessagesListItem> = [
  {
    title: "Message",
    dataIndex: "message",
    key: "message",
    ...getColumnSearchProps<MessagesListItem>("message"),
  },

  {
    title: "Created",
    dataIndex: "createdAt",
    key: "createdAt",
    width: 200,
    sorter: (a, b) =>
      moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf(),
    render: (value: string) => moment(value).format("MMM D, YYYY - h:mm A"),
  },
];
