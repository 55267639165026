import { useCallback, useEffect, useRef } from "react";

export const useDebounce = <CallbackArgs extends any[]>(
  callback: (...args: CallbackArgs) => void,
  wait = 100
) => {
  const timeout = useRef<ReturnType<typeof setTimeout>>();
  useEffect(
    () => () => {
      timeout.current && clearTimeout(timeout.current);
      timeout.current = void 0;
    },
    [wait, callback]
  );
  return useCallback(
    (...args: CallbackArgs) => {
      const { current } = timeout;
      current && clearTimeout(current);
      timeout.current = setTimeout(() => {
        timeout.current = void 0;
        callback(...args);
      }, wait);
    },
    [wait, callback]
  );
};