import React, { FC, memo, useEffect } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import Dashboard from "@/pages/dashboard";
import Users from "@/pages/users";
import Layout from "@/components/Layout";
import Auth from "@/pages/auth";
import ProtectedRoute from "@/components/RequiredAuth";
import Blog from "@/pages/articles";
import AddEditPost from "@/pages/add-edit-article";
import AddEditFAQ from "@/pages/add-edit-faq";
import FAQ from "@/pages/faq";
import MailList from "@/pages/maillist";
import Applications from "./pages/applications";
import StatisticsList from "./pages/statistics";

interface AppProps {
  isAuth: boolean;
}
const App: FC<AppProps> = ({ isAuth }) => {
  const navigate = useNavigate();
  let location = useLocation();
  useEffect(() => {
    if (isAuth && location?.pathname === "/login") {
      navigate("/", { replace: true });
    }
  }, [isAuth, location, navigate]);
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route
          index
          element={
            <ProtectedRoute isAuth={isAuth}>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="users"
          element={
            <ProtectedRoute isAuth={isAuth}>
              <Users />
            </ProtectedRoute>
          }
        />
        <Route
          path="articles"
          element={
            <ProtectedRoute isAuth={isAuth}>
              <Blog />
            </ProtectedRoute>
          }
        />
        <Route
          path="articles/add"
          element={
            <ProtectedRoute isAuth={isAuth}>
              <AddEditPost />
            </ProtectedRoute>
          }
        />
        <Route
          path="articles/edit/:postId"
          element={
            <ProtectedRoute isAuth={isAuth}>
              <AddEditPost isEdit />
            </ProtectedRoute>
          }
        />
        <Route
          path="faq"
          element={
            <ProtectedRoute isAuth={isAuth}>
              <FAQ />
            </ProtectedRoute>
          }
        />
        <Route
          path="faq/add"
          element={
            <ProtectedRoute isAuth={isAuth}>
              <AddEditFAQ />
            </ProtectedRoute>
          }
        />
        <Route
          path="faq/edit/:faqId"
          element={
            <ProtectedRoute isAuth={isAuth}>
              <AddEditFAQ isEdit />
            </ProtectedRoute>
          }
        />
        <Route
          path="mail-list"
          element={
            <ProtectedRoute isAuth={isAuth}>
              <MailList />
            </ProtectedRoute>
          }
        />
        <Route
          path="statistics-list"
          element={
            <ProtectedRoute isAuth={isAuth}>
              <StatisticsList />
            </ProtectedRoute>
          }
        />
        <Route
          path="applications"
          element={
            <ProtectedRoute isAuth={isAuth}>
              <Applications />
            </ProtectedRoute>
          }
        />
      </Route>


      <Route path="/login" element={<Auth />} />
      <Route path="*" element={<div>404</div>} />
    </Routes>
  );
};

export default memo(App);
