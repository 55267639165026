import { createSlice } from "@reduxjs/toolkit";
import { getDashboardUsers } from "@/store/dashboard/thunks";

const initialState: any = {
  loading: false,
  users: [],
};
export const dashboard = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    // setActiveApplication(state, action) {
    //   // state.activeApplication = action.payload;
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDashboardUsers.fulfilled, (state, { payload }) => {
        state.users = payload;
        state.loading = false;
      })
      .addCase(getDashboardUsers.rejected, (state) => {
        state.loading = false;
      });
  },
});
// export const {
//   // resetBackgroundCheckState,
// } = dashboard.actions;
export default dashboard.reducer;
