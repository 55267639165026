import React, { memo, FC } from "react";
import { Form, Input, Button } from "antd";
import { signInUser } from "@/store/auth/thunks";
import { useAppDispatch, useAppSelector } from "@/store/store";
import * as S from "./styles";
import { authLoadingSelector } from "@/store/auth/selectors";

interface AuthPageProps {}

const AuthPage: FC<AuthPageProps> = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const loading = useAppSelector(authLoadingSelector);

  const onSubmitForm = (values: any) => {
    dispatch(
      signInUser({
        phone: "+1" + values.phone,
        password: values.password,
      })
    );
  };
  return (
    <S.Wrapper>
      <S.LoginBlock>
        <S.Header>Cityline admin panel</S.Header>
        <Form form={form} onFinish={onSubmitForm} autoComplete="off">
          <S.FormBlock>
            <S.InputTitle>Login</S.InputTitle>
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Login is required",
                },
              ]}
            >
              <Input
                onChange={(evt) =>
                  form.setFieldsValue({
                    phone: evt.target.value.replace(/[^0-9]/gi, ""),
                  })
                }
              />
            </Form.Item>
            <S.InputTitle>Password</S.InputTitle>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Password is required",
                },
              ]}
            >
              <Input
                type="password"
                onChange={(evt) =>
                  form.setFieldsValue({
                    password: evt.target.value.replace(/ /gi, ""),
                  })
                }
              />
            </Form.Item>
          </S.FormBlock>
          <S.ButtonBlock>
            <Button type="primary" htmlType="submit" disabled={loading}>
              Sign in
            </Button>
          </S.ButtonBlock>
        </Form>
      </S.LoginBlock>
    </S.Wrapper>
  );
};

export default memo(AuthPage);
