import React, { memo, FC, useEffect } from "react";
import { Table } from "antd";
import { getDashboardUsers } from "@/store/dashboard/thunks";
import { useAppDispatch, useAppSelector } from "@/store/store";
import {
  dashboardLoadingSelector,
  dashboardTableSelector,
} from "@/store/dashboard/selectors";
import { dashboardColumns } from "@/utils/helpers/columns";

interface DashboardProps {}

const Dashboard: FC<DashboardProps> = () => {
  const dispatch = useAppDispatch();
  const dashboardTable = useAppSelector(dashboardTableSelector);
  const loading = useAppSelector(dashboardLoadingSelector);

  useEffect(() => {
    dispatch(getDashboardUsers());
  }, [dispatch]);

  return (
    <>
      <h1>Admin Dashboard</h1>
      <Table
        bordered
        loading={loading}
        size="small"
        columns={dashboardColumns}
        dataSource={dashboardTable}
        sticky
        pagination={false}
        scroll={{ x: "max-content" }}
        rowKey="totalUsers"
      />
    </>
  );
};

export default memo(Dashboard);
