import React, { memo, FC, useState, useEffect, useCallback } from "react";
import moment from "moment";
import { Button, Form, Input, Switch } from "antd";
import { changeFormValue } from "@/utils/helpers/functions";
import { useAppDispatch, useAppSelector } from "@/store/store";
import {
  checkUniqueUrl,
  createBlogPost,
  editBlogPost,
  getPostById,
} from "@/store/blog/thunks";
import { useNavigate, useParams } from "react-router-dom";
import {
  activePostLoadingSelector,
  activePostSelector,
  createPostLoadingSelector,
  isUniqueUrlSelector,
  uniqueUrlCheckingSelector,
} from "@/store/blog/selectors";
import Editor from "@/components/Editor";
import { useDebounce } from "@/utils/hooks/useDebounce";
import {
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import * as S from "./styles";
import { useCheckData } from "@/utils/hooks/useCheckData";

interface AddBlogItemProps {
  isEdit?: boolean;
}

const AddBlogItem: FC<AddBlogItemProps> = ({ isEdit }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { postId } = useParams();
  const dispatch = useAppDispatch();
  const [editor, setEditor] = useState("");
  const [previewPhoto, setPreviewPhoto] = useState<{ originFileObj?: File }>(
    {}
  );
  const post = useAppSelector(activePostSelector);
  const loading = useAppSelector(activePostLoadingSelector);
  const createPostLoading = useAppSelector(createPostLoadingSelector);
  const uniqueUrlChecking = useAppSelector(uniqueUrlCheckingSelector);
  const isUniqueUrl = useAppSelector(isUniqueUrlSelector);

  const { isOneKeyFilled: isShowMainPhoto } = useCheckData({
    previewPhoto: previewPhoto.originFileObj,
    postPhoto: post?.previewPhoto,
  });

  const checkUrlCallback = useCallback(
    (url: string) => {
      dispatch(checkUniqueUrl(url));
    },
    [dispatch]
  );

  const checkUrl = useDebounce(checkUrlCallback, 700);

  const submitForm = async (values: any) => {
    if (isEdit && postId) {
      await dispatch(
        editBlogPost({
          ...values,
          id: postId,
          body: editor,
          file: previewPhoto.originFileObj,
        })
      );
      dispatch(getPostById(postId));
      return;
    }
    dispatch(
      createBlogPost({
        ...values,
        body: editor,
        file: previewPhoto.originFileObj,
      })
    );
    navigate("/articles");
  };

  useEffect(() => {
    if (isEdit && postId) {
      dispatch(getPostById(postId));
    }
  }, [dispatch, isEdit, postId]);

  useEffect(() => {
    if (post) {
      const { body, title, url } = post;
      setEditor(body);
      form.setFieldsValue({ title, url });
    }
  }, [form, post]);

  return (
    <>
      <S.Header>
        <h1>{isEdit ? "Save" : "Create"} article</h1>
        {!!post && (
          <S.Header>
            <S.ActiveSwitchBlock>
              <b>Active:</b>
              <Switch
                disabled
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={post.isActive}
              />
            </S.ActiveSwitchBlock>
            <div>
              <b>Created:</b>{" "}
              {moment(post.createdAt).format("MMM D, YYYY - h:mm A")}
            </div>
            <div>
              <b>Updated:</b>{" "}
              {moment(post.updatedAt).format("MMM D, YYYY - h:mm A")}
            </div>
          </S.Header>
        )}
      </S.Header>

      <S.FormWrapper
        form={form}
        onFinish={submitForm}
        autoComplete="off"
        onValuesChange={(values: any) => changeFormValue(values, form)}
        colon={false}
      >
        <div>
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: "Please input title" }]}
          >
            <Input
              onChange={({ target: { value: url } }) => {
                // .replace(/[^0-9a-zA-Z -]/gi, "");
                form.setFieldsValue({
                  url: url.trim().toLowerCase().replace(/ /gi, "_"),
                });
                if (post?.url !== url) {
                  checkUrl(url);
                }
              }}
            />
          </Form.Item>
          <Form.Item
            label="cityline.io/articles/"
            name="url"
            rules={[{ required: true, message: "Please input url" }]}
            validateStatus={!isUniqueUrl ? "error" : ""}
            help={!isUniqueUrl ? "The URL is not unique" : ""}
          >
            <Input
              disabled
              suffix={uniqueUrlChecking ? <LoadingOutlined /> : <span />}
            />
          </Form.Item>
          <S.Editor>
            <Editor
              defaultValue={post?.body}
              editor={editor}
              setEditor={setEditor}
            />
          </S.Editor>
          <S.Button>
            <Form.Item>
              <Button
                disabled={!isUniqueUrl}
                type="primary"
                htmlType="submit"
                loading={loading || createPostLoading || uniqueUrlChecking}
              >
                {isEdit ? "Save" : "Create"} article
              </Button>
            </Form.Item>
          </S.Button>
        </div>
        <div>
          <S.Subtitle>Article main photo</S.Subtitle>

          <S.ArticleImageWrapper>
            {isShowMainPhoto && (
              <>
                {previewPhoto.originFileObj && (
                  <S.ArticleImage
                    src={URL.createObjectURL(previewPhoto.originFileObj)}
                  />
                )}
                {!previewPhoto.originFileObj && post?.previewPhoto && (
                  <S.ArticleImage
                    src={`${process.env.REACT_APP_API_URL}/${post?.previewPhoto}`}
                  />
                )}
              </>
            )}
          </S.ArticleImageWrapper>

          <S.UploadImage
            action="/"
            customRequest={(dummy: any) => {
              dummy.onSuccess("ok");
            }}
            showUploadList={false}
            accept="image/*"
            maxCount={1}
            onChange={(data) => setPreviewPhoto(data.fileList[0])}
          >
            <Button icon={<UploadOutlined />} style={{ width: "100%" }}>
              Upload main image
            </Button>
          </S.UploadImage>
        </div>
      </S.FormWrapper>
    </>
  );
};

export default memo(AddBlogItem);
