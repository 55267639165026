export enum UserRoles {
  AGENT = 0,
  TENANT = 1,
  LANDLORD = 2,
  ADMIN = 3,
}

export interface UsersListItem {
  organizationName: any;
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  source: string;
  organization: any;
  propertiesCount: number;
  unitsCount: number;
  roles: string[];
  createdAt: string;
  isInvitationAccepted: string;
  applicationInvitesCount: number;
}
