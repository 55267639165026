import styled from "styled-components";
import { Form, Upload } from "antd";

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
`;
export const Subtitle = styled.h2`
  margin-bottom: 0;
  line-height: 20px;
`;

export const ActiveSwitchBlock = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

export const FormWrapper = styled(Form)`
  display: grid;
  grid-template-columns: 1fr 300px;
  grid-column-gap: 20px;
  padding-bottom: 10px;
`;

export const Editor = styled.div`
  & .ql-tooltip.ql-editing {
    z-index: 1;
  }
`;
export const Button = styled.div`
  margin-top: 20px;
`;
export const UploadImage = styled(Upload)`
  & .ant-upload {
    width: 100% !important;
  }
`;

export const ArticleImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  height: 200px;
  border: 1px solid #ccc;
`;

export const ArticleImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
