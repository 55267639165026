import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "@/axiosInstance";
import { notification } from "antd";

export const getFaqs = createAsyncThunk("FAQ/getFaqs", async () => {
  try {
    const { data } = await axiosInstance.get("/admin/faq");
    return data;
  } catch (error: any) {
    const {
      response: { data: errorMessage },
    } = error;
    notification.error({
      message: errorMessage,
      placement: "top",
    });
  }
});

export const getFaqById = createAsyncThunk(
  "FAQ/getFaqById",
  async (faqId: string) => {
    try {
      const { data } = await axiosInstance.get(`/admin/faq/${faqId}`);
      return data;
    } catch (error: any) {
      const {
        response: { data: errorMessage },
      } = error;
      notification.error({
        message: errorMessage,
        placement: "top",
      });
    }
  }
);

export const createFaq = createAsyncThunk(
  "FAQ/createFaq",
  async (values: object, { dispatch }) => {
    try {
      await axiosInstance.post("/admin/faq", values);
      dispatch(getFaqs());
      notification.success({
        message: "Question successfully created",
        placement: "top",
      });
    } catch (error: any) {
      const {
        response: { data: errorMessage },
      } = error;
      notification.error({
        message: errorMessage,
        placement: "top",
      });
    }
  }
);

export const editFaq = createAsyncThunk(
  "FAQ/editFaq",
  async (
    values: { id: string; isActive?: boolean; position?: number },
    { dispatch }
  ) => {
    try {
      await axiosInstance.patch("/admin/faq", values);
      dispatch(getFaqs());
      notification.success({
        message: "FAQ successfully edited",
        placement: "top",
      });
    } catch (error: any) {
      const {
        response: { data: errorMessage },
      } = error;
      notification.error({
        message: errorMessage,
        placement: "top",
      });
    }
  }
);

export const deleteFaq = createAsyncThunk(
  "FAQ/deleteFaq",
  async (id: number, { dispatch }) => {
    try {
      await axiosInstance.delete(`/admin/faq/${id}`);
      dispatch(getFaqs());
    } catch (error: any) {
      const {
        response: { data: errorMessage },
      } = error;
      notification.error({
        message: errorMessage,
        placement: "top",
      });
    }
  }
);
