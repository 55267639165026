import { createSlice } from "@reduxjs/toolkit";
import { createFaq, editFaq, getFaqById, getFaqs } from "@/store/faq/thunks";

const initialState: any = {
  loading: false,
  createFaqLoading: false,
  activeFaqLoading: false,
  activeFaq: null,
  list: [],
};
export const faq = createSlice({
  name: "faq",
  initialState,
  reducers: {
    setActiveFaq(state, { payload }) {
      state.activeFaq = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFaqs.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFaqs.fulfilled, (state, { payload }) => {
        state.list = payload;
        state.loading = false;
      })
      .addCase(getFaqs.rejected, (state) => {
        state.loading = false;
      });
    builder.addCase(getFaqById.fulfilled, (state, { payload }) => {
      state.activeFaq = payload;
    });

    builder
      .addCase(createFaq.pending, (state) => {
        state.createFaqLoading = true;
      })
      .addCase(createFaq.fulfilled, (state) => {
        state.createFaqLoading = false;
      })
      .addCase(createFaq.rejected, (state) => {
        state.createFaqLoading = false;
      });

    builder
      .addCase(editFaq.pending, (state) => {
        state.activeFaqLoading = true;
      })
      .addCase(editFaq.fulfilled, (state) => {
        state.activeFaqLoading = false;
      })
      .addCase(editFaq.rejected, (state) => {
        state.activeFaqLoading = false;
      });
  },
});
export const { setActiveFaq } = faq.actions;
export default faq.reducer;
