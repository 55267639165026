//@ts-nocheck
import { FC, useCallback, useEffect } from "react";
import { useQuill } from "react-quilljs";
import { axiosInstance } from "@/axiosInstance";
import { notification } from "antd";

interface EditorProps {
  defaultValue?: string;
  editor: string;
  setEditor: (value: string) => void;
}

const Editor: FC<EditorProps> = ({ defaultValue, editor, setEditor }) => {
  const { quill, quillRef, Quill } = useQuill({
    modules: {
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image"],
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" },
          ],
          [{ color: [] }],
          ["clean"],
        ],
      },

      // resize: {
      //   modules: ["Resize", "DisplaySize"],
      //   parchment: {
      //     image: {
      //       // attribute: ["width", "height"], // ['width', 'height']
      //       limit: {
      //         maxWidth: 200,
      //       },
      //     },
      //   },
      // },
      // blotFormatter: {},
    },
  });
  const selectLocalImage = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file: any = input && input.files ? input.files[0] : null;
      const formData = new FormData();
      formData.append("file", file);

      try {
        const {
          data: { path },
        } = await axiosInstance.post("/files", formData);
        const range = quill!.getSelection();
        quill!.insertEmbed(
          range!.index,
          "image",
          `${process.env.REACT_APP_API_URL}/${path}`
        );
      } catch (error: any) {
        const {
          response: { data: errorMessage },
        } = error;
        notification.error({
          message: errorMessage,
          placement: "top",
        });
      }
    };
  }, [quill]);

  if (Quill && !quill) {
    // Quill.register("modules/imageResize", ImageResize);
  }

  useEffect(() => {
    if (quill) {
      quill.on("text-change", (delta, oldContents) => {
        setEditor(quill.root.innerHTML);
      });
      quill.getModule("toolbar").addHandler("image", selectLocalImage);
    }
  }, [quill, Quill, setEditor, selectLocalImage]);

  useEffect(() => {
    if (quill && defaultValue) {
      const delta = quill.clipboard.convert(defaultValue);
      quill.setContents(delta);
    }
  }, [defaultValue, quill, setEditor]);
  return (
    <div>
      <div ref={quillRef} />
    </div>
  );
};

export default Editor;
