import { RootState } from "@/store/store";

export const usersTableSelector = (state: RootState) => state.users.list;

export const organizationsSelector = (state: RootState) => state.users.organizations;

export const usersTableLoadingSelector = (state: RootState): boolean =>
  state.users.loading;

export const getPage = (state: RootState): number => state.users.page;

export const getLimit = (state: RootState): number => state.users.limit;

export const getSort = (state: RootState): any => state.users.sort;

export const getSearch = (state: RootState): any => state.users.search;