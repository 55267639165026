import { RootState } from "@/store/store";
import { BlogPostItem } from "@/utils/types/blog";

export const postsListSelector = (state: RootState): BlogPostItem[] =>
  state.blog.list;

export const postsListLoadingSelector = (state: RootState): boolean =>
  state.blog.loading;

export const activePostSelector = (state: RootState): BlogPostItem =>
  state.blog.activePost;

export const activePostLoadingSelector = (state: RootState): boolean =>
  state.blog.activePostLoading;

export const createPostLoadingSelector = (state: RootState): boolean =>
  state.blog.createPostLoading;

export const uniqueUrlCheckingSelector = (state: RootState): boolean =>
  state.blog.uniqueUrlChecking;

export const isUniqueUrlSelector = (state: RootState): boolean =>
  state.blog.isUniqueUrl;
