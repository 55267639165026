import React, { memo, FC } from "react";
import { Outlet } from "react-router-dom";
import * as S from "./styles";
import Menu from "../Menu";
import Logo from "@/components/icons/Logo";

interface LayoutProps {}

const Layout: FC<LayoutProps> = () => {
  return (
    <S.Wrapper>
      <S.Header>
        <Logo width={30} />
        <nav>
          <Menu />
        </nav>
      </S.Header>
      <main>
        <Outlet />
      </main>
    </S.Wrapper>
  );
};

export default memo(Layout);
